<template>
  <v-app-bar
    elevation="0"
    color="transparent"
    class="appbar"
  >
    <!-- TESS Logo -->
    <img src="@/assets/images/logo.png" alt="TESS" class="logosh"> 
    
    <!-- Navigation -->
    <div class="hidden-sm-and-down links" style="max-height: 48px;">

      <!-- Links -->
      <v-btn 
        v-for="(item, index) in items"
        :key="index"
        v-scroll-to="item.to"
        :to="item.to"
        class="header_link" text>{{ item.title }}</v-btn>

        <v-btn 
        :href="this.$t('rules')"
        target="_blank"
        class="header_link" text>{{ this.$t('header_rules') }}</v-btn>
    </div>
    <img src="@/assets/images/araz.png" alt="TESS"  height="20px"> 
    <!-- User buttons -->
    <div class="hidden-sm-and-down user_buttons">
      <!-- Login button -->
      <v-btn v-if="isLogin === false" :to="'/login'" class="buttonh pr-6" text>
        {{ $t('header_login') }}
      </v-btn>
      
      <!-- Personal Cabinet button -->
      <v-btn v-else :to="'/profil'" class="buttonh pr-6" text>
        {{ $t('header_personal_cabinet') }}
      </v-btn>

      <!-- Signout button -->
      <v-btn
        class="buttonh"
        @click="logOut"
        v-if="isLogin"
        text
      >
        ÇIXIŞ       
      </v-btn>
    </div>

    <!-- Header: Mobile version -->
    <div class="hidden-md-and-up">
      <v-menu :close-on-content-click="false" offset-y>
        <template v-slot:activator="{on, attrs}">
          <v-app-bar-nav-icon  
          v-bind="attrs"
          v-on="on"
          class="white--text no-back"
          ></v-app-bar-nav-icon>
        </template>
        <v-list>
          <v-list-item
          v-for="(item, index) in items"
          :key="index"
          :to="item.to"
          v-scroll-to="item.to"
          >
          <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
          <v-list-item
          target="_blank"
          :href="this.$t('rules') "
          >

          <v-list-item-title>{{ this.$t('header_rules')}}</v-list-item-title>
          </v-list-item>
          <v-list-item
          :to="'/profil'"
          >

          <v-list-item-title>{{  $t('personal_area') }}</v-list-item-title>
          </v-list-item>

          <v-list-item
          v-if="isLogin"
          @click="logOut"
          >
          <v-list-item-title>{{  $t('exit') }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </v-app-bar>
</template>

<script>
import user from '../../store/user'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Header',
  data: () => ({
    isLogin: false,
    lang: 'az'
  }),
  computed: {
    items() {
      return [
        { title: this.$t('header_home'), to: "#head" },
        { title: this.$t('header_mechanics'), to: "#conditions" },
        { title: this.$t('header_prizes_gallery'), to: "#gifts" },
        { title: "QALİBLƏR", to: "#qalibler" },
        { title: this.$t('header_questions_n_answers'), to: "#faq" },
      ]
    }
  },
  methods: {
    logOut(){
      this.loading = true
      user.dispatch('updateLogin', {user:'', expires: -1})
      this.loading = false
      location.href='/'
      //  this.$router.push('/login')
    },
    changeLocation(){
      if(this.$i18n.locale == 'az'){
        localStorage.setItem('lang','ru')
        this.$i18n.locale = 'ru'
      }
      else{
        localStorage.setItem('lang','az')
        this.$i18n.locale='az'
      }
      // location.reload()
    }
  },
  created(){
    this.isLogin = user.getters.is_login 
    if (localStorage.getItem('lang')){
      this.lang = localStorage.getItem('lang');
    }
    else {
      localStorage.setItem('lang', 'az');
      this.lang = 'az';
    }
  }
}
</script>

<style>
  @import url('../../assets/css/custom.css');
  .v-icon{
    color:#5B69AF!important

  }
</style>
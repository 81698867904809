import Vue from "vue"
import Vuex from "vuex"

Vue.use(Vuex)

export default new Vuex.Store({
    state : {
        // deyisenleri global olanlari burada teyin edirik
        url:'https://www.tesspromo.az/api/',
        cities: [],
        regions: [],
        companies: []
    },
    mutations : {
        // mutationun isi state deyerini deyismekdi
        // ---------------------------
        // initState(state, items){
        //     state.items = items
        // }
        // ---------------------------

        initCity(state,cities){
            state.cities = cities
        },
        initRegion(state,regions){
            state.regions = regions
        },
        initCompany(state,companies){
            state.companies = companies
        }

    },
    actions : {
        // asenkron olaraq alacaqimiz islerde database api falan bunu istifade edeceyik

        updateCity(context,cities){
            context.commit('initCity',cities)
        },
        updateRegion(context,regions){
            context.commit('initRegion',regions)
        },
        updateCompany(context,companies){
            context.commit('initCompany',companies)
        },

    },
    getters : {
        // state icindeki datayi bize geri dondurur
        // ----------------------------
        // getState(state){
        //     return state.items
        // }
        // ----------------------------
        // istifade seklidi de buna benzerdir parametre olaraq state alir ve oda yuxarida gosterilen state gosterir
        getUrl(state){

          return state.url
        },
        getCities(state){
            return state.cities
        },
        getRegions(state){

            return state.regions
        },
        getCompanies(state){

            return state.companies
        },
    }
})

import Vue from "vue"
import Vuex from "vuex"
import store from './user'

Vue.use(Vuex)

export default new Vuex.Store({
    state : {
        // deyisenleri global olanlari burada teyin edirik
        user : ''
    },
    mutations : {
        // mutationun isi state deyerini deyismekdi
        // ---------------------------
        // initState(state, items){
        //     state.items = items
        // }
        // ---------------------------
        initUser(state,user){
            state.user = user
        }
    },
    actions : {
        // asenkron olaraq alacaqimiz islerde database api falan bunu istifade edeceyik
        updateUser(context,user){
            context.commit('initUser',user)
        },
        setCookie(context,obj) {
            var d = new Date();
            d.setTime(d.getTime() + (obj.expires* 60 * 60 * 1000));
            var expires = "expires="+d.toUTCString();
            document.cookie = obj.user + "=" + obj.value + ";" + expires + ";path=/";
          },
        updateLogin(context,obj){
          // context.commit('initToken',obj.token)
          context.commit('initUser',obj.user)
          var d = new Date();
          d.setTime(d.getTime() + (obj.expires* 60 * 60 * 1000));
          var expires = "expires="+d.toUTCString();
          document.cookie = 'user' + "=" + JSON.stringify(obj.user) + ";" + expires + ";path=/";
          // document.cookie = 'token' + "=" + obj.value2 + ";" + expires + ";path=/";
        }
    },
    getters : {
        // state icindeki datayi bize geri dondurur
        // ----------------------------
        // getState(state){
        //     return state.items
        // }
        // ----------------------------
        // istifade seklidi de buna benzerdir parametre olaraq state alir ve oda yuxarida gosterilen state gosterir
        getUser(state){

            return state.user
        },
        getUserRole(state){

          return state.user.role
        },
        getToken(state){
          return state.user.token
 
        },
        getUserCookie() {
            var cname='user'
            var name = cname + "=";
            var ca = document.cookie.split(';');
            for(var i = 0; i < ca.length; i++) {
              var c = ca[i];
              while (c.charAt(0) == ' ') {
                c = c.substring(1);
              }
              if (c.indexOf(name) == 0) {
                return JSON.parse(c.substring(name.length, c.length));
              }
            }
            return "";
          },
          is_login(){
            if(store.getters.getUser != ''){
              return true
            }
            if(store.getters.getUserCookie != ''){
              store.dispatch('updateUser',store.getters.getUserCookie)
                return true
            }

              return false
          }
    }
})

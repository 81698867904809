<template>
  <v-main class="menu2" theme="light">
    <Header2 :pageIsLogin="true" />
    <v-container fluid>
      <v-row class="login-row">
        <!-- Tea set image -->
        <v-col
          cols="12"
          sm="0"
          md="6"
          class="d-none d-md-flex no-padding "
        >
          <img src="@/assets/images/gifts/tea_set.png" alt="Tess tea set" class="max-width">
        </v-col>
        
        <!-- Form -->
        <v-col
          cols="12"
          sm="12"
          md="6"
          class="no-padding justify-center d-flex align-self-center"
        >
          <v-card elevation="0" class="px-5 mx-4 login-cart justify-center">
            <v-card-text>
              <v-form @submit.prevent="signIn" id="sign" ref="form">
                <!-- Mobile number -->
                <v-text-field
                  class="mb-3 form_field"
                  :label="$t('register_field_mobile')"
                  v-model="mobileNumber"
                  :rules='rules.mobile'
                  type="text"
                  outlined
                  dark
                  color="#fff"
                ></v-text-field>

                <!-- Password -->
                <v-text-field
                  class="mb-3 form_field"
                  :label="$t('register_field_password')"
                  v-model="password"
                  :rules="rules.password"
                  type="password"
                  outlined
                  dark
                  color="#fff"
                ></v-text-field>
              </v-form>

              <!-- Forgot password -->
              <v-btn class="forgotPasswordButton" @click="sendPass" text>
                {{ $t('login_forgot_password_button') }}
              </v-btn>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
              class="px-10 buttonh"
              form="sign"
              :loading="loading"
              dark
              rounded
              type="submit"
              >{{ $t('login_button') }}</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios"
import store from '../store/'
import user from '../store/user'
import Header2 from "./parts/Header2.vue"
import i18n from "@/plugins/i18n"

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Login',
  components: { Header2 },
  data: () => ({
    mobileNumber: "",
    password: "",
    loading: false,
    rules: {}
  }),
  methods: {
    signIn() {
      if (this.$refs.form.validate()) {
        if (994000000000 > Number(this.mobileNumber))
          this.mobileNumber = 994000000000 + Number(this.mobileNumber);

        this.loading = true;
        axios.post(store.getters.getUrl + "user/login", { msisdn: this.mobileNumber.toString(), password: this.password })
        .then(response => {
          if (response.data.success) {
            this.$toast.success(i18n.t("login_success"));
            user.dispatch("updateLogin", { expires: 1, user: response.data.value });
            this.loading = false;
            this.$router.push('/profil')
          }
          else {
            this.$toast.error(i18n.t("register_error"));
            this.loading = false;
          }
        })
      }
      else {
        this.$toast.error(i18n.t('register_wrong_filling'));
        this.loading = false;
      }
    },
    sendPass() {
      if (this.mobileNumber) {
        if (994000000000 > Number(this.mobileNumber))
          this.mobileNumber = 994000000000 + Number(this.mobileNumber);

        this.loading = true;
        axios.get(store.getters.getUrl + "user/sendpassword?msisdn=" + this.mobileNumber)
        .then(response => {
          if (response.data.success)
            this.$toast.success(i18n.t('login_sms_sent_success'));
          else {
            this.$toast.error(i18n.t('login_register_not_possible'));
            this.loading = false;
          }
        });
      }
      else {
        this.$toast.error(i18n.t('login_enter_mobile_number'));
        this.loading = false;
      }
    },
  },
  computed: {
    formIsValid() {
      if (this.mobileNumber == "")
        return false;
      if (this.password == "")
        return false;
      return true;
    }
  },
  created() {
    this.rules = {
      mobile: [
        val => val > 500000000 && val < 994999999999 || i18n.t('register_error_number_example'),
      ],
      password: [
        val => val !== "" || i18n.t('register_error_please_fill'),
      ]
    }
  }
}
</script>

<style>
@import url('../assets/css/custom.css');
.login-cart{
  width: 650px;
}
.login-row{
  width: 100%!important;
  height: 100%;
}
.all-page{
  width: 100%;
  height: 100%;
}
.forgotPasswordButton{
  color: #5B69AF !important;
  text-transform: none !important;
}
</style>
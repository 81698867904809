<template>
  <v-main class="menu">
    <!-- Upper part -->
    <div class="menu1">
      <Header />
      <v-row class="">
        <v-col
          cols="12"
          lg="6"
          md="6"
          sm="12"
          class="pb-0 text-center"
          >
          <img src="@/assets/images/left-img.png" alt="Tess" width="100%">

          </v-col>
          <v-col
          cols="12"
          lg="6"
          md="6"
          sm="12"
          class="pa-0 text-center"
          >
          <img src="@/assets/images/TESS_CLUB_SITE_last.png" alt="Shah Gold" class="max-width">
          <div class="text-center">
            <b style="color: red;font-size: 30px;">18 oktyabr saat 16:00 5000 AZN çəkilişi keçiriləcək.</b>
          </div>
        </v-col>
      </v-row>



    </div>

    <!-- Conditions -->
    <div id="conditions">
      <v-container class="py-0">
        <v-row class="pt-16">
          <!-- Condition 1 -->
          <v-col
          cols="12"
          class="text-center"
          >
            <img src="@/assets/images/mexanika.png" alt="mexanika" width="100%">
          </v-col>
          
        </v-row>

      </v-container>
    </div>

    <!-- Gifts -->
    <div id="gifts" class="my-16">
      <v-container class="py-0">
        <v-row class="pt-16">
          <!-- Condition 1 -->
          <v-col
          cols="12"
          class="text-center"
          >
            <img src="@/assets/images/mukafat.png" alt="mexanika" width="100%">
          </v-col>
          
        </v-row>

      </v-container>
    </div>
    <div class="hr" id="qalibler">
    </div>
    <h2 class="text-center"><span class="menu1_head1">QALİBLƏR</span></h2>
    <v-container>
      <v-row class="pt-10">

          <Winner/>
      </v-row>
    </v-container>

    <!-- FAQ -->
    <div id="faq" class="my-16">
      <h2 class="text-center"><span class="menu1_head1">{{ $t('faq_header') }}</span></h2>
      
      <div class="container">
        <div class="">
          <div class="faqContainer">
            <v-row>
              <!-- Questions and Answers -->
              <v-col 
                cols="12"
                lg="12"
                md="12"
                sm="12"
                class="questionsContainer">
                <QuestionsAnswers />
              </v-col>
              

            </v-row>
          </div>
        </div>
      </div>

    </div>
    <div>
      <v-row>
        <v-col
        cols="12"
        lg="12"
        md="12"
        sm="12"
        class="pb-0 text-center"
        >
          <div>
            <v-btn
            href="https://www.youtube.com/channel/UCChV_NR6wxXEz-Ji_J8wrcw/videos"
            rounded
            target="_block"
            class="buttonh mb-10" text>BÜTÜN TİRAJLAR</v-btn>
          </div>
          <iframe class="ifrm" src="https://www.youtube.com/embed/live_stream?channel=UCChV_NR6wxXEz-Ji_J8wrcw" frameborder="0" allowfullscreen></iframe>
        </v-col>
      </v-row>
    </div>
    <v-container class="py-0">
        <v-row class="pt-16">
          <!-- Condition 1 -->
          <v-col
          cols="12"
          class="text-left"
          >
            <img src="@/assets/images/footer.png" alt="mexanika" style="max-width: 80%;">
          </v-col>
          
        </v-row>

      </v-container>
  </v-main>
</template>

<script>
import Header from "../parts/Header.vue"
import QuestionsAnswers from '../parts/QuestionsAnswers.vue'
import Winner from "../parts/Winner.vue"
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Index',
  data: () => ({
    items: [],
  }),
  components: { Header, QuestionsAnswers, Winner },
  methods: {
    toFiscalEnter() {
      this.$router.push('/fiscalEnter')
    },

  }
}
</script>

<style>
@import url('../../assets/css/custom.css');
</style>